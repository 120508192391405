<template>
  <mdb-container fluid>
    <!--Section: Overview-->
    <section class="p-3 pb-5">
      <mdb-row>
        <mdb-col lg="6" class="mb-4">
          <mdb-card narrow>
            <mdb-view cascade gradient="blue" class="text-uppercase">
              Übersicht für {{ this.accountData.company }}
            </mdb-view>
            <mdb-card-body cascade>
              <mdb-tbl>
                <mdb-tbl-head>
                  <tr>
                    <th>Veranstaltung</th>
                    <th>{{ this.eventData.label }}</th>
                  </tr>
                </mdb-tbl-head>
                <mdb-tbl-body>
                  <tr>
                    <th>Datum</th>
                    <td>{{ this.eventData.date }}</td>
                  </tr>
                  <tr>
                    <th>Öffnungszeiten</th>
                    <td>{{ this.eventData.start }} - {{ this.eventData.end }}</td>
                  </tr>
                  <tr>
                    <th>Veranstaltungsort</th>
                    <td>{{ this.eventData.street }}<br>{{ this.eventData.zip }} {{ this.eventData.city }}, {{ this.eventData.country }}</td>
                  </tr>
                  <tr>
                    <th>Währung</th>
                    <td class='font-weight-bold'>{{ this.accountData.currency }}</td>
                  </tr>
                  <tr v-show="this.usergroup === 'supplier'">
                    <th>App-Zugangsdaten</th>
                    <td>
                      <p class="mb-1"><a class="blue-text font-weight-bold" href="https://app.gww-lf.de" target="_blank">https://app.gww-lf.de</a></p>
                      <p class="mb-1">Benutzername: <b>{{ this.accountData.username }}</b></p>
                      <p class="mb-1">App-Passwort: <b>{{ this.accountData.api_key }}</b></p>
                    </td>
                  </tr>
                  <tr>
                    <th>Dokumentation</th>
                    <td v-show="this.usergroup === 'trader'">
                      <p class="mb-1"><a class="blue-text font-weight-bold" href="LeadForge 4.0 GÄSTEMANAGEMENT.pdf" target="_blank">Gästemanagement 5.0 [PDF]</a></p>
                      <p class="mb-1"><a class="blue-text font-weight-bold" href="LeadForge 4.0 MESSEANFRAGEMANAGEMENT Berater.pdf" target="_blank">Messeanfragemanagement 5.0 [PDF]</a></p>
                    </td>
                    <td v-show="this.usergroup === 'supplier'">
                      <p class="mb-1"><a class="blue-text font-weight-bold" href="LeadForge 4.0 PRODUKTDATENBANK.pdf" target="_blank">Produktdatenbank 5.0 [PDF]</a></p>
                      <p class="mb-1"><a class="blue-text font-weight-bold" href="LeadForge 5.0 PRODUKTDATENGUIDE.pdf" target="_blank">Produktdatenguide 5.0 [PDF]</a></p>
                      <p class="mb-1"><a class="blue-text font-weight-bold" href="LeadForge 4.1 MOBILE - ALLGEMEIN.pdf" target="_blank">Mobile 5.0 [PDF]</a></p>
                      <p class="mb-1"><a class="blue-text font-weight-bold" href="LeadForge 4.0 MESSEANFRAGEMANAGEMENT Lieferant.pdf" target="_blank">Messeanfragemanagement 5.0 [PDF]</a></p>
                    </td>
                  </tr>
                </mdb-tbl-body>
              </mdb-tbl>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
        <mdb-col lg="6" class="mb-4" v-show="this.usergroup === 'organizer'">
          <mdb-card narrow>
            <mdb-view cascade gradient="blue">
              Top 5 - Händler
            </mdb-view>
            <mdb-card-body cascade>
              <table class="table">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Firma</th>
                        <th>Besucher</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(trader, index) in organizerData.trader_toplist" :key="trader.company">
                        <td>{{index + 1}}</td>
                        <td>{{trader.company}}</td>
                        <td>{{trader.client_count}}</td>
                    </tr>
                </tbody>
              </table>
              <hr>
              <h6 class="font-weight-bold grey-text mt-4">Export</h6>
              <p class="mb-1"><a href="https://gww-lf.de/api/export_traders.php" target="_blank">Erschienene Besucher pro Händler</a></p>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
        <mdb-col lg="6" class="mb-4" v-show="this.usergroup === 'trader' || this.usergroup === 'supplier'">
          <!--Card: Checklist-->
          <mdb-card narrow>
            <mdb-view cascade gradient="blue" class="text-uppercase">
              Checkliste
            </mdb-view>
            <mdb-card-body cascade>
              <span v-show="this.usergroup === 'trader'">
                <mdb-progress :height="16" :value=invitedPercentage v-show="invitedPercentage < 100" color="yellow" class="mb-1" />
                <mdb-progress :height="16" :value=invitedPercentage v-show="invitedPercentage === 100" color="green" class="mb-1" />
                <mdb-card-text>{{ this.invitationData.invited }} von {{ this.invitationData.all }} Einladungen versendet (<span class="font-weight-bold">{{ invitedPercentage }}%</span>)</mdb-card-text>
                <mdb-progress :height="16" :value=authorizedPercentage v-show="authorizedPercentage < 100" color="yellow" class="mt-4 mb-1" />
                <mdb-progress :height="16" :value=authorizedPercentage v-show="authorizedPercentage === 100" color="green" class="mt-4 mb-1" />
                <mdb-card-text>{{ this.ticketData.authorized }} von {{ this.responseData.confirmed }} Tickets versendet (<span class="font-weight-bold">{{ authorizedPercentage }}%</span>)</mdb-card-text>
              </span>
              <mdb-progress :height="16" :value=completedRequestsPercentage v-show="completedRequestsPercentage < 100" color="yellow" class="mt-4 mb-1" />
              <mdb-progress :height="16" :value=completedRequestsPercentage v-show="completedRequestsPercentage === 100" color="green" class="mt-4 mb-1" />
              <mdb-card-text v-show="this.usergroup === 'supplier'">{{ this.requestData.completed }} von {{ this.requestData.total }} Anfragen freigegeben (<span class="font-weight-bold">{{ completedRequestsPercentage }}%</span>)</mdb-card-text>
              <mdb-card-text v-show="this.usergroup === 'trader'">{{ this.requestData.completed }} von {{ this.requestData.total }} Anfragen abgeschlossen (<span class="font-weight-bold">{{ completedRequestsPercentage }}%</span>)</mdb-card-text>
              <hr>
              <p class="mb-3 dark-grey-text">Haben Sie Ihre Vorbereitungen für diese Veranstaltung abgeschlossen? Der Status wird dem Veranstalter übermittelt.</p>
              <mdb-switch onLabel="Ja" offLabel="Nein" v-model="accountData.preparationStatus" @getValue="togglePreparation" />
              <hr>
              <p class="mb-3 dark-grey-text">Wollen Sie dem Support-Team Zugriff auf ihren Account gewähren?</p>
              <mdb-switch onLabel="Ja" offLabel="Nein" v-model="accountData.consentStatus" @getValue="toggleConsent" />
            </mdb-card-body>
          </mdb-card>
          <!--/.Card: Checklist-->
        </mdb-col>
      </mdb-row>
    </section>
    <!--/.Section: Overview-->
    <!--Section: Progress-->
    <section class="p-3 pb-5" v-show="this.usergroup === 'trader'">
      <mdb-row>
        <!--Card: Invitation-->
        <mdb-col xl="3" md="6" class="mb-4">
          <mdb-card class="cascading-admin-card">
            <mdb-view cascade class="admin-up">
              <mdb-icon icon="envelope" far class="blue"></mdb-icon>
              <div class="data">
                <p class="text-uppercase">Einladungen</p>
                <h4 class="font-weight-bold dark-grey-text">{{ invitedCount }}</h4>
              </div>
            </mdb-view>
            <mdb-card-body cascade>
              <mdb-progress :height="16" :value=invitedPercentage color="grey" class="mb-1" />
              <mdb-card-text>{{ this.invitationData.invited }} von {{ this.invitationData.all }} Einladungen versendet (<span class="font-weight-bold">{{ invitedPercentage }}%</span>)</mdb-card-text>
              <mdb-progress :height="16" :value=errorPercentage color="grey" class="mt-4 mb-1" />
              <mdb-card-text>{{ this.invitationData.error }} von {{ this.invitationData.invited }} Einladungen nicht zustellbar (<span class="font-weight-bold">{{ errorPercentage }}%</span>)</mdb-card-text>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
        <!--/.Card: Invitation-->
        <!--Card: Response-->
        <mdb-col xl="3" md="6" class="mb-4">
          <mdb-card class="cascading-admin-card">
            <mdb-view cascade class="admin-up">
              <mdb-icon icon="calendar-check" far class="blue"></mdb-icon>
              <div class="data">
                <p class="text-uppercase">Rückmeldungen</p>
                <h4 class="font-weight-bold dark-grey-text">{{ this.responseData.all }}</h4>
              </div>
            </mdb-view>
            <mdb-card-body cascade>
              <mdb-progress :height="16" :value=confirmedPercentage color="grey" class="mb-1" />
              <mdb-card-text>{{ this.responseData.confirmed }} von {{ invitedCount }} Teilnehmern haben zugesagt (<span class="font-weight-bold">{{ confirmedPercentage }}%</span>)</mdb-card-text>
              <mdb-progress :height="16" :value=cancelledPercentage color="grey" class="mt-4 mb-1" />
              <mdb-card-text>{{ this.responseData.cancelled }} von {{ invitedCount }} Teilnehmern haben abgesagt (<span class="font-weight-bold">{{ cancelledPercentage }}%</span>)</mdb-card-text>
              <mdb-progress :height="16" :value=deletedPercentage color="grey" class="mt-4 mb-1" />
              <mdb-card-text>{{ this.responseData.deleted }} von {{ invitedCount }} Teilnehmern haben eine Löschung nach DSGVO beantragt (<span class="font-weight-bold">{{ deletedPercentage }}%</span>)</mdb-card-text>          
              <mdb-progress :height="16" :value=pendingPercentage color="grey" class="mt-4 mb-1" />
              <mdb-card-text>{{ this.responseData.pending }} von {{ invitedCount }} Teilnehmern haben sich noch nicht zurückgemeldet (<span class="font-weight-bold">{{ pendingPercentage }}%</span>)</mdb-card-text>
            </mdb-card-body>
            <mdb-card-footer>
              <mdb-btn class="float-right" size="sm" outline="blue" @click.native="openExport">Export der DSGVO Löschungen</mdb-btn>    
            </mdb-card-footer>
          </mdb-card>
        </mdb-col>
        <!--/.Card: Response-->
        <!--Card: Ticket-->
        <mdb-col xl="3" md="6" class="mb-4">
          <mdb-card class="cascading-admin-card">
            <mdb-view cascade class="admin-up">
              <mdb-icon icon="ticket-alt" class="blue"></mdb-icon>
              <div class="data">
                <p class="text-uppercase">Tickets</p>
                <h4 class="font-weight-bold dark-grey-text">{{ this.ticketData.authorized }}</h4>
              </div>
            </mdb-view>
            <mdb-card-body cascade>
              <mdb-progress :height="16" :value=authorizedPercentage color="grey" class="mb-1" />
              <mdb-card-text>{{ this.ticketData.authorized }} von {{ this.responseData.confirmed }} Tickets versendet (<span class="font-weight-bold">{{ authorizedPercentage }}%</span>)</mdb-card-text>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
        <!--/.Card: Ticket-->
        <!--Card: Visitor-->
        <mdb-col xl="3" md="6" class="mb-4">
          <mdb-card class="cascading-admin-card">
            <mdb-view cascade class="admin-up">
              <mdb-icon icon="user-check" class="blue"></mdb-icon>
              <div class="data">
                <p class="text-uppercase">Besucher</p>
                <h4 class="font-weight-bold dark-grey-text">{{ this.visitationData.visited }}</h4>
              </div>
            </mdb-view>
            <mdb-card-body cascade>
              <mdb-progress :height="16" :value=visitedPercentage color="grey" class="mb-1" />
              <mdb-card-text>{{ this.visitationData.visited }} von {{ this.ticketData.authorized }} Teilnehmern erschienen (<span class="font-weight-bold">{{ visitedPercentage }}%</span>)</mdb-card-text>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
        <!--/.Card: Visitor-->
      </mdb-row>
    </section>
    <!--/.Section: Progress-->
    <!--Section: Requests-->
    <section class="p-3 pb-5" v-show="this.usergroup === 'trader' || this.usergroup === 'supplier'">
      <mdb-row>
        <!--Card: Visitor Spread-->
        <mdb-col xl="3" md="6" class="mb-5" v-show="this.usergroup === 'trader'">
          <mdb-card class="cascading-admin-card">
            <mdb-view cascade class="admin-up">
              <mdb-icon icon="clock" class="blue"></mdb-icon>
              <div class="data">
                <p class="text-uppercase">Besucherverteilung</p>
              </div>
            </mdb-view>
            <mdb-card-body cascade>
              <mdb-bar-chart
                :data="visitationSpreadData"
                :options="visitationSpreadOptions"
                :width="600"
                :height="300">
              </mdb-bar-chart>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
        <!--/.Card: Visitor Spread-->
        <!--Card: Request-->
        <mdb-col xl="3" md="6" class="mb-4">
          <mdb-card class="cascading-admin-card">
            <mdb-view cascade class="admin-up">
              <mdb-icon icon="file-invoice" fas class="blue"></mdb-icon>
              <div class="data">
                <p class="text-uppercase">Anfragen</p>
                <h4 class="font-weight-bold dark-grey-text">{{ requestData.total }}</h4>
              </div>
              <div class="data pr-4 mr-4 border-right">
                <p class="text-uppercase">Kunden</p>
                <h4 class="font-weight-bold dark-grey-text">{{ requestData.clients }}</h4>
              </div>
              <div class="data pr-4 mr-4 border-right" v-show="this.usergroup === 'supplier'">
                <p class="text-uppercase">Berater</p>
                <h4 class="font-weight-bold dark-grey-text">{{ requestData.traders }}</h4>
              </div>
            </mdb-view>
            <mdb-card-body cascade>
              <section v-show="this.usergroup === 'supplier'">
                <mdb-progress :height="16" :value=completedRequestsPercentage color="grey" class="mb-1" />
                <mdb-card-text>{{ this.requestData.completed }} von {{ this.requestData.total }} Anfragen freigegeben (<span class="font-weight-bold">{{ completedRequestsPercentage }}%</span>)</mdb-card-text>
              </section>
              <section v-show="this.usergroup === 'trader'">
                <mdb-progress :height="16" :value=completedRequestsPercentage color="grey" class="mb-1" />
                <mdb-card-text>{{ this.requestData.completed }} von {{ this.requestData.total }} Anfragen abgeschlossen (<span class="font-weight-bold">{{ completedRequestsPercentage }}%</span>)</mdb-card-text>
              </section>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
        <!--/.Card: Request-->
        <!--Card: Product Toplist-->
        <mdb-col xl="3" md="6" class="mb-4" v-show="this.usergroup === 'supplier'">
          <mdb-card class="cascading-admin-card">
            <mdb-view cascade class="admin-up">
              <mdb-icon icon="cube" fas class="blue"></mdb-icon>
              <div class="data">
                <p class="text-uppercase">Top 10 Produkte</p>
              </div>
            </mdb-view>
            <mdb-card-body cascade>
              <table class="table">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Produkt</th>
                        <th>Anzahl</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(product, index) in productData.product_toplist" :key="product.label">
                        <td>{{index + 1}}</td>
                        <td>{{product.label}}</td>
                        <td>{{product.request_count}}</td>
                    </tr>
                </tbody>
              </table>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
        <!--/.Card: Product Toplist-->
      </mdb-row>
    </section>
    <!--/.Section: Requests-->
    <!--Section: Organizer-->
    <section class="p-3 pb-5" v-show="this.usergroup === 'organizer'">
      <mdb-row>
        <!--Card: Invitation-->
        <mdb-col xl="3" md="6" class="mb-4">
          <mdb-card class="cascading-admin-card">
            <mdb-view cascade class="admin-up">
              <mdb-icon icon="envelope" far class="blue"></mdb-icon>
              <div class="data">
                <p class="text-uppercase">Einladungen</p>
                <h4 class="font-weight-bold dark-grey-text">{{ this.organizerData.all_invited }}</h4>
              </div>
            </mdb-view>
            <mdb-card-body cascade>
              <mdb-progress :height="16" :value=organizerInvitedPercentage color="grey" class="mb-1" />
              <mdb-card-text>{{ this.organizerData.all_invited }} von {{ this.organizerData.all }} angelegten Teilnehmern wurden eingeladen (<span class="font-weight-bold">{{ organizerInvitedPercentage }}%</span>)</mdb-card-text>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
        <!--/.Card: Invitation-->
        <!--Card: Response-->
        <mdb-col xl="3" md="6" class="mb-4">
          <mdb-card class="cascading-admin-card">
            <mdb-view cascade class="admin-up">
              <mdb-icon icon="calendar-check" far class="blue"></mdb-icon>
              <div class="data">
                <p class="text-uppercase">Zusagen</p>
                <h4 class="font-weight-bold dark-grey-text">{{ this.organizerData.all_confirmed }}</h4>
              </div>
            </mdb-view>
            <mdb-card-body cascade>
              <mdb-progress :height="16" :value=organizerConfirmedPercentage color="grey" class="mb-1" />
              <mdb-card-text>{{ this.organizerData.all_confirmed }} von {{ this.organizerData.all_invited }} eingeladenen Teilnehmern haben zugesagt (<span class="font-weight-bold">{{ organizerConfirmedPercentage }}%</span>)</mdb-card-text>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
        <!--/.Card: Response-->
        <!--Card: Ticket-->
        <mdb-col xl="3" md="6" class="mb-4">
          <mdb-card class="cascading-admin-card">
            <mdb-view cascade class="admin-up">
              <mdb-icon icon="ticket-alt" class="blue"></mdb-icon>
              <div class="data">
                <p class="text-uppercase">Tickets</p>
                <h4 class="font-weight-bold dark-grey-text">{{ this.organizerData.all_authorized }}</h4>
              </div>
            </mdb-view>
            <mdb-card-body cascade>
              <mdb-progress :height="16" :value=organizerAuthorizedPercentage color="grey" class="mb-1" />
              <mdb-card-text>{{ this.organizerData.all_authorized }} von {{ this.organizerData.all_confirmed }} Zusagen haben ein Ticket erhalten (<span class="font-weight-bold">{{ organizerAuthorizedPercentage }}%</span>)</mdb-card-text>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
        <!--/.Card: Ticket-->
        <!--Card: Visitor-->
        <mdb-col xl="3" md="6" class="mb-4">
          <mdb-card class="cascading-admin-card">
            <mdb-view cascade class="admin-up">
              <mdb-icon icon="user-check" class="blue"></mdb-icon>
              <div class="data">
                <p class="text-uppercase">Besucher</p>
                <h4 class="font-weight-bold dark-grey-text">{{ this.organizerData.all_visited }}</h4>
              </div>
            </mdb-view>
            <mdb-card-body cascade>
              <mdb-progress :height="16" :value=organizerVisitedPercentage color="grey" class="mb-1" />
              <mdb-card-text>{{ this.organizerData.all_visited }} von {{ this.organizerData.all_authorized }} Tickets wurden gescanned (<span class="font-weight-bold">{{ organizerVisitedPercentage }}%</span>)</mdb-card-text>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
        <!--/.Card: Visitor-->
      </mdb-row>
      <mdb-row>
        <!--Card: Visitor Spread-->
        <mdb-col xl="3" md="6" class="mb-5">
          <mdb-card class="cascading-admin-card">
            <mdb-view cascade class="admin-up">
              <mdb-icon icon="clock" class="blue"></mdb-icon>
              <div class="data">
                <p class="text-uppercase">Besucherverteilung</p>
              </div>
            </mdb-view>
            <mdb-card-body cascade>
              <mdb-bar-chart
                :data="visitationSpreadData"
                :options="visitationSpreadOptions"
                :width="600"
                :height="300">
              </mdb-bar-chart>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
        <!--/.Card: Visitor Spread-->
      </mdb-row>
    </section>
    <!--/.Section: Organizer-->
  </mdb-container>
</template>

<script>
import Vue from 'vue'
import { bus } from '../../main'
import { mdbContainer, mdbRow, mdbCard, mdbCardBody, mdbCardText, mdbCardFooter, mdbCol, mdbIcon, mdbView, mdbProgress, mdbTbl, mdbTblHead, mdbTblBody, mdbSwitch, mdbBarChart, mdbBtn } from 'mdbvue'
import axios from 'axios'

export default {
  name: 'Dashboard',
  components: {
    mdbContainer,
    mdbRow,
    mdbCard,
    mdbCardBody,
    mdbCardText,
    mdbCardFooter,
    mdbCol,
    mdbIcon,
    mdbView,
    mdbProgress,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbSwitch,
    mdbBarChart,
    mdbBtn
  },
  data() {
    return {
      loading: false,
      accountData: [],
      eventData: [],
      invitationData: [],
      responseData: [],
      ticketData: [],
      visitationData: [],
      organizerData: [],
      requestData: [],
      productData: [],
      usergroup: localStorage.getItem('usergroup'),
      visitationSpreadData: {
        labels: [],
        datasets: []
      },
      visitationSpreadOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            barPercentage: 1,
            gridLines: {
              display: false,
              color: "rgba(0, 0, 0, 0.1)"
            }
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
              userCallback: function(label) {
                // when the floored value is the same as the value we have a whole number
                if (Math.floor(label) === label) {
                  return label;
                }
              }
            },
            gridLines: {
              display: false,
              color: "rgba(0, 0, 0, 0.1)"
            }
          }]
        }
      }
    }
  },
  computed: {
    invitedPercentage() {
      return this.calculatePercentage(this.invitationData.invited, this.invitationData.all)
    },
    errorPercentage() {
      return this.calculatePercentage(this.invitationData.error, this.invitationData.all)
    },
    invitedCount() {
      return this.invitationData.invited - this.invitationData.error
    },
    responsePercentage() {
      return this.calculatePercentage(this.responseData.all, this.invitedCount)
    },
    confirmedPercentage() {
      return this.calculatePercentage(this.responseData.confirmed, this.invitedCount)
    },
    cancelledPercentage() {
      return this.calculatePercentage(this.responseData.cancelled, this.invitedCount)
    },
    deletedPercentage() {
      return this.calculatePercentage(this.responseData.deleted, this.invitedCount)
    },
    pendingPercentage() {
      return 100 - this.confirmedPercentage - this.cancelledPercentage - this.deletedPercentage
    },
    authorizedPercentage() {
      return this.calculatePercentage(this.ticketData.authorized, this.responseData.confirmed)
    },
    visitedPercentage() {
      return this.calculatePercentage(this.visitationData.visited, this.ticketData.authorized)
    },
    organizerInvitedPercentage() {
      return this.calculatePercentage(this.organizerData.all_invited, this.organizerData.all)
    },
    organizerConfirmedPercentage() {
      return this.calculatePercentage(this.organizerData.all_confirmed, this.organizerData.all_invited)
    },
    organizerAuthorizedPercentage() {
      return this.calculatePercentage(this.organizerData.all_authorized, this.organizerData.all_confirmed)
    },
    organizerVisitedPercentage() {
      return this.calculatePercentage(this.organizerData.all_visited, this.organizerData.all_authorized)
    },
    completedRequestsPercentage() {
      return this.calculatePercentage(this.requestData.completed, this.requestData.total)
    }
  },
  created() {
    bus.$on('eventChange', this.eventChange)
  },
  beforeDestroy() {
    bus.$off('eventChange', this.eventChange)
  },
  mounted: function() {
    if (localStorage.usergroup) {
      this.usergroup = localStorage.usergroup
    }
    this.loadModel()
    fetch("https://gww-lf.de/api/chart_visitations_spread.php")
      .then(res => res.json())
      .then(json => {
        const dataset = {
          label: 'Besucher',
          display: false,
          data: json.datasets.data,
          backgroundColor: 'rgba(33, 150, 243, 0.2)',
          borderColor: 'rgba(33, 150, 243, 1)',
          borderWidth: 2
        }
        this.visitationSpreadData = {
          ...this.barChartData,
          labels: json.labels,
          datasets: [dataset]
        }
      })
  },
  methods: {
    eventChange: function () {
      this.loadModel()
      fetch("https://gww-lf.de/api/chart_visitations_spread.php")
        .then(res => res.json())
        .then(json => {
          const dataset = {
            label: 'Besucher',
            display: false,
            data: json.datasets.data,
            backgroundColor: 'rgba(33, 150, 243, 0.2)',
            borderColor: 'rgba(33, 150, 243, 1)',
            borderWidth: 2
          }
          this.visitationSpreadData = {
            ...this.barChartData,
            labels: json.labels,
            datasets: [dataset]
          }
        })
    },
    togglePreparation: function (value) {
      this.loading = true
      var url = 'toggle_preparation.php'
      axios({
        method: 'post',
        url: url,
        data: value
      }).then(response => {
        this.loading = false
        if (response.data.success === false) {
          this.accountData.preparationStatus = !this.accountData.preparationStatus
        }
      }).catch(error => {
        this.loading = false
        alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
      })
    },
    toggleConsent: function (value) {
      this.loading = true
      var url = 'toggle_consent.php'
      axios({
        method: 'post',
        url: url,
        data: value
      }).then(response => {
        this.loading = false
        if (response.data.success === false) {
          this.accountData.consentStatus = !this.accountData.consentStatus
        }
      }).catch(error => {
        this.loading = false
        alert('Die Verbindung konnte nicht hergestellt werden oder wurde unterbrochen. Bitte versuchen Sie es später erneut (' + error.message + ')')
      })
    },
    loadModel () {
      axios.get('get_account_data.php').then(response => {
        this.accountData = response.data
      })
      axios.get('get_event.php').then(response => {
        this.eventData = response.data
      })
      axios.get('chart_invitations.php').then(response => {
        this.invitationData = response.data
      })
      axios.get('chart_responses.php').then(response => {
        this.responseData = response.data
      })
      axios.get('chart_tickets.php').then(response => {
        this.ticketData = response.data
      })
      axios.get('chart_visitations.php').then(response => {
        this.visitationData = response.data
      })
      // axios.get('chart_visitations_spread.php').then(response => {
      //   this.visitationSpreadData.labels = response.data.labels
      //   this.visitationSpreadData.datasets.data = response.data.datasets.data
      //   this.visitationSpreadData.datasets.backgroundColor = response.data.datasets.backgroundColor
      //   this.visitationSpreadData.datasets.borderColor = response.data.datasets.borderColor
      // })
      axios.get('chart_organizer.php').then(response => {
        this.organizerData = response.data
      })
      axios.get('chart_requests.php').then(response => {
        this.requestData = response.data
      })
      axios.get('chart_products.php').then(response => {
        this.productData = response.data
      })
    },
    calculatePercentage: function (fraction, whole) {
      var percentage = fraction / whole * 100
      if (percentage > 99) {
        percentage = Math.floor(percentage)
      } else {
        percentage = Math.round(percentage)
      }
      if (!isNaN(percentage)) {
        return percentage  
      } else {
        return 0
      }
    },
    openExport: function () {
      var url = Vue.prototype.$serverUrl + 'api/export_participants.php?action=deleted'
      window.open(url, '_blank', 'noreferrer');
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
